.ais-Breadcrumb-list,
.ais-CurrentRefinements-list,
.ais-HierarchicalMenu-list,
.ais-Hits-list,
.ais-Results-list,
.ais-InfiniteHits-list,
.ais-InfiniteResults-list,
.ais-Menu-list,
.ais-NumericMenu-list,
.ais-Pagination-list,
.ais-RatingMenu-list,
.ais-RefinementList-list,
.ais-ToggleRefinement-list {
  margin: 0;
  padding: 0;
  list-style: none;
}
.ais-InfiniteHits-list {
  display: flex;
  flex-wrap: wrap;
}
.ais-ClearRefinements-button,
.ais-CurrentRefinements-delete,
.ais-CurrentRefinements-reset,
.ais-GeoSearch-redo,
.ais-GeoSearch-reset,
.ais-HierarchicalMenu-showMore,
.ais-InfiniteHits-loadPrevious,
.ais-InfiniteHits-loadMore,
.ais-InfiniteResults-loadMore,
.ais-Menu-showMore,
.ais-RangeInput-submit,
.ais-RefinementList-showMore,
.ais-SearchBox-submit,
.ais-SearchBox-reset,
.ais-VoiceSearch-button {
  padding: 0;
  overflow: visible;
  font: inherit;
  line-height: normal;
  color: inherit;
  background: none;
  border: 0;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}
.ais-ClearRefinements-button::-moz-focus-inner,
.ais-CurrentRefinements-delete::-moz-focus-inner,
.ais-CurrentRefinements-reset::-moz-focus-inner,
.ais-GeoSearch-redo::-moz-focus-inner,
.ais-GeoSearch-reset::-moz-focus-inner,
.ais-HierarchicalMenu-showMore::-moz-focus-inner,
.ais-InfiniteHits-loadPrevious::-moz-focus-inner,
.ais-InfiniteHits-loadMore::-moz-focus-inner,
.ais-InfiniteResults-loadMore::-moz-focus-inner,
.ais-Menu-showMore::-moz-focus-inner,
.ais-RangeInput-submit::-moz-focus-inner,
.ais-RefinementList-showMore::-moz-focus-inner,
.ais-SearchBox-submit::-moz-focus-inner,
.ais-SearchBox-reset::-moz-focus-inner,
.ais-VoiceSearch-button::-moz-focus-inner {
  padding: 0;
  border: 0;
}
.ais-ClearRefinements-button[disabled],
.ais-CurrentRefinements-delete[disabled],
.ais-CurrentRefinements-reset[disabled],
.ais-GeoSearch-redo[disabled],
.ais-GeoSearch-reset[disabled],
.ais-HierarchicalMenu-showMore[disabled],
.ais-InfiniteHits-loadPrevious[disabled],
.ais-InfiniteHits-loadMore[disabled],
.ais-InfiniteResults-loadMore[disabled],
.ais-Menu-showMore[disabled],
.ais-RangeInput-submit[disabled],
.ais-RefinementList-showMore[disabled],
.ais-SearchBox-submit[disabled],
.ais-SearchBox-reset[disabled],
.ais-VoiceSearch-button[disabled] {
  cursor: default;
}
.ais-InfiniteHits-loadPrevious,
.ais-InfiniteHits-loadMore,
.ais-HierarchicalMenu-showMore,
.ais-Menu-showMore,
.ais-RefinementList-showMore {
  overflow-anchor: none;
}
.ais-Breadcrumb-list,
.ais-Breadcrumb-item,
.ais-Pagination-list,
.ais-RangeInput-form,
.ais-RatingMenu-link,
.ais-PoweredBy {
  display: flex;
  align-items: center;
}
.ais-GeoSearch,
.ais-GeoSearch-map {
  height: 100%;
}
.ais-HierarchicalMenu-list .ais-HierarchicalMenu-list {
  margin-left: 1em;
}
.ais-PoweredBy-logo {
  display: block;
  height: 1.2em;
  width: auto;
}
.ais-PoweredBy-text {
  margin-right: 0.3rem;
}
.ais-RatingMenu-starIcon {
  display: block;
  width: 20px;
  height: 20px;
}
.ais-SearchBox-input::-ms-clear,
.ais-SearchBox-input::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}
.ais-SearchBox-input::-webkit-search-decoration,
.ais-SearchBox-input::-webkit-search-cancel-button,
.ais-SearchBox-input::-webkit-search-results-button,
.ais-SearchBox-input::-webkit-search-results-decoration {
  display: none;
}
.ais-RangeSlider .rheostat {
  overflow: visible;
  margin-top: 40px;
  margin-bottom: 40px;
}
.ais-RangeSlider .rheostat-background {
  height: 6px;
  top: 0px;
  width: 100%;
}
.ais-RangeSlider .rheostat-handle {
  margin-left: -12px;
  top: -7px;
}
.ais-RangeSlider .rheostat-background {
  position: relative;
  background-color: #fff;
  border: 1px solid #aaa;
}
.ais-RangeSlider .rheostat-progress {
  position: absolute;
  top: 1px;
  height: 4px;
  background-color: #333;
}
.rheostat-handle {
  position: relative;
  z-index: 1;
  width: 20px;
  height: 20px;
  background-color: #fff;
  border: 1px solid #333;
  border-radius: 50%;
  cursor: grab;
}
.rheostat-marker {
  margin-left: -1px;
  position: absolute;
  width: 1px;
  height: 2px;
}
.rheostat-marker--large {
  height: 9px;
}
.rheostat-value {
  margin-left: 50%;
  padding-top: 15px;
  position: absolute;
  text-align: center;
  transform: translateX(-50%);
}
.rheostat-tooltip {
  margin-left: 50%;
  position: absolute;
  top: -22px;
  text-align: center;
  transform: translateX(-50%);
}
[class^='ais-'] {
  box-sizing: border-box;
}
[class^='ais-'][class$='--disabled'],
[class^='ais-'][class$='--disabled'] *,
.ais-VoiceSearch-button:disabled {
  cursor: not-allowed;
}
.ais-HierarchicalMenu-showMore,
.ais-Menu-showMore,
.ais-RefinementList-showMore,
.ais-ClearRefinements-button,
.ais-InfiniteHits-loadMore,
.ais-RangeInput-submit,
.ais-InfiniteHits-loadPrevious,
.ais-Pagination-link,
.ais-GeoSearch-redo,
.ais-GeoSearch-reset,
.ais-VoiceSearch-button,
.ais-RelevantSort-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: #fff;
  background-image: linear-gradient(-180deg, #fff, #fcfcfd);
  border: 1px solid #d6d6e7;
  border-radius: 3px;
  box-shadow: 0 1px 0 0 rgba(35, 38, 59, 0.05);
  color: #23263b;
  cursor: pointer;
  display: inline-flex;
  font-size: 0.875rem;
  font-weight: 400;
  height: 2rem;
  justify-content: center;
  padding: 0 1rem;
  place-items: center;
  text-decoration: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  font-family: Poppins;
  font-size: 16px;
  line-height: 16px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}
.ais-HierarchicalMenu-showMore,
.ais-Menu-showMore,
.ais-RefinementList-showMore,
.ais-GeoSearch-redo,
.ais-GeoSearch-reset {
  height: 1.5rem;
}
.ais-HierarchicalMenu-showMore,
.ais-Menu-showMore,
.ais-RefinementList-showMore {
  margin-top: 1rem;
}
.ais-HierarchicalMenu-showMore:hover,
.ais-Menu-showMore:hover,
.ais-RefinementList-showMore:hover,
.ais-ClearRefinements-button:hover,
.ais-InfiniteHits-loadMore:hover,
.ais-RangeInput-submit:hover,
.ais-InfiniteHits-loadPrevious:hover,
.ais-Pagination-item:not(.ais-Pagination-item--selected):not(
    .ais-Pagination-item--disabled
  )
  .ais-Pagination-link:hover,
.ais-GeoSearch-redo:hover,
.ais-GeoSearch-reset:hover,
.ais-VoiceSearch-button:hover {
  background-image: linear-gradient(-180deg, #fff, #f5f5fa);
  border-color: #d6d6e7;
}
.ais-HierarchicalMenu-showMore:focus,
.ais-Menu-showMore:focus,
.ais-RefinementList-showMore:focus,
.ais-ClearRefinements-button:focus,
.ais-InfiniteHits-loadMore:focus,
.ais-RangeInput-submit:focus,
.ais-InfiniteHits-loadPrevious:focus,
.ais-GeoSearch-redo:focus,
.ais-GeoSearch-reset:focus,
.ais-VoiceSearch-button:focus {
  background-image: linear-gradient(-180deg, #fff, #f5f5fa);
  border-color: #3c4fe0;
  box-shadow: #3c4fe0 0 0 0 1px, rgba(35, 38, 59, 0.05) 0 2px 0 1px;
  outline: currentcolor none medium;
}
.ais-HierarchicalMenu-showMore:active,
.ais-Menu-showMore:active,
.ais-RefinementList-showMore:active,
.ais-ClearRefinements-button:active,
.ais-InfiniteHits-loadMore:active,
.ais-InfiniteHits-loadPrevious:active,
.ais-Pagination-item--selected .ais-Pagination-link,
.ais-Pagination-link:active,
.ais-GeoSearch-redo:active,
.ais-GeoSearch-reset:active,
.ais-VoiceSearch-button:active {
  border-color: #d6d6e7;
  box-shadow: rgba(119, 122, 175, 0.4) 0 1px 4px 0 inset,
    rgba(119, 122, 175, 0.4) 0 1px 1px 0 inset, rgba(35, 38, 59, 0.05) 0 1px 0 0;
}
.ais-ClearRefinements-button:disabled[disabled],
.ais-InfiniteHits-loadMore:disabled[disabled],
.ais-InfiniteHits-loadPrevious:disabled[disabled],
.ais-Pagination-item--disabled .ais-Pagination-link,
.ais-GeoSearch-redo--disabled,
.ais-GeoSearch-reset--disabled,
.ais-VoiceSearch-button:disabled {
  background-image: linear-gradient(-180deg, #fff, #f5f5fa);
  border-color: #efeff5;
  box-shadow: none;
  color: #b6b7d5;
}
.ais-HierarchicalMenu-showMore--disabled[disabled],
.ais-Menu-showMore--disabled[disabled],
.ais-RefinementList-showMore--disabled[disabled] {
  display: none;
}
.ais-NumericMenu-label,
.ais-ToggleRefinement-label,
.ais-HierarchicalMenu-item,
.ais-Menu-item,
.ais-RatingMenu-item,
.ais-RefinementList-item {
  display: block;
  font-size: 0.875rem;
  line-height: 1.75rem;
}
.ais-HierarchicalMenu-link,
.ais-Menu-link,
.ais-RatingMenu-link,
.ais-RefinementList-label {
  align-items: center;
  cursor: pointer;
  display: flex;
  flex-wrap: nowrap;
}
.ais-Breadcrumb-link,
.ais-HierarchicalMenu-link,
.ais-Menu-link,
.ais-RatingMenu-link {
  color: inherit;
  text-decoration: none;
}
.ais-Breadcrumb-link:hover {
  text-decoration: underline;
}
.ais-HierarchicalMenu-label,
.ais-Menu-label,
.ais-RefinementList-labelText {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.ais-HierarchicalMenu-item--selected
  > .ais-HierarchicalMenu-link
  > .ais-HierarchicalMenu-label,
.ais-Menu-item--selected .ais-Menu-label,
.ais-RefinementList-item--selected,
.ais-RatingMenu-item--selected {
  font-weight: 600;
}
.ais-ToggleRefinement-count,
.ais-HierarchicalMenu-count,
.ais-Menu-count,
.ais-RatingMenu-count,
.ais-RefinementList-count {
  background: #f5f5fa none repeat scroll 0% 0%;
  border: 1px solid #b6b7d5;
  border-radius: 99999px;
  color: #23263b;
  display: inline-flex;
  flex-shrink: 0;
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1rem;
  margin-left: 0.5rem;
  overflow: hidden;
  padding: 0 0.25rem;
}
.ais-Menu-noResults,
.ais-HierarchicalMenu-noResults,
.ais-RefinementList-noResults {
  color: #5a5e9a;
  font-size: 0.875rem;
  line-height: 1.25rem;
  margin-top: 1rem;
}
.ais-MenuSelect-select,
.ais-HitsPerPage-select,
.ais-SortBy-select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: #fff;
  background-image: url('data:image/svg+xml;utf8,<svg width="14" height="24" xmlns="http://www.w3.org/2000/svg"><g stroke="%23777aaf" stroke-width="2" fill="none" fill-rule="evenodd"><path d="M13 7L7 1 1 7M1 17l6 6 6-6"/></g></svg>'),
    linear-gradient(-180deg, #fff, #fcfcfd);
  background-position: right 1rem center, 0 0;
  background-repeat: no-repeat;
  background-size: 10px, auto;
  border: 1px solid #d6d6e7;
  border-radius: 3px;
  box-shadow: rgba(35, 38, 59, 0.05) 0 1px 0 0;
  color: #23263b;
  cursor: pointer;
  font: inherit;
  height: 2.5rem;
  outline: currentcolor none medium;
  padding: 0 2.5rem 0 1rem;
  position: relative;
  width: 100%;
}
.ais-HitsPerPage-select:-moz-focusring,
.ais-HitsPerPage-select::-moz-focus-inner,
.ais-SortBy-select:-moz-focusring,
.ais-SortBy-select::-moz-focus-inner {
  color: rgba(0, 0, 0, 0);
  outline: currentcolor none medium;
}
.ais-HitsPerPage-select > option:not(:checked),
.ais-SortBy-select > option:not(:checked) {
  color: #23263b;
}
.ais-HitsPerPage-select > option:disabled,
.ais-SortBy-select > option:disabled {
  color: #b6b7d5;
}
.ais-HitsPerPage-select:hover,
.ais-SortBy-select:hover {
  background-image: url('data:image/svg+xml;utf8,<svg width="14" height="24" xmlns="http://www.w3.org/2000/svg"><g stroke="%23777aaf" stroke-width="2" fill="none" fill-rule="evenodd"><path d="M13 7L7 1 1 7M1 17l6 6 6-6"/></g></svg>'),
    linear-gradient(-180deg, #fcfcfd, #f5f5fa);
  border-color: #d6d6e7;
}
.ais-HitsPerPage-select:focus,
.ais-SortBy-select:focus {
  background-image: url('data:image/svg+xml;utf8,<svg width="14" height="24" xmlns="http://www.w3.org/2000/svg"><g stroke="%23777aaf" stroke-width="2" fill="none" fill-rule="evenodd"><path d="M13 7L7 1 1 7M1 17l6 6 6-6"/></g></svg>'),
    linear-gradient(-180deg, #fff, #f5f5fa);
  border-color: #3c4fe0;
  box-shadow: #3c4fe0 0 0 0 1px, rgba(35, 38, 59, 0.05) 0 2px 0 0;
}
.ais-HitsPerPage-select:disabled,
.ais-SortBy-select:disabled {
  background-image: url('data:image/svg+xml;utf8,<svg width="14" height="24" xmlns="http://www.w3.org/2000/svg"><g stroke="%23b6b7d5" stroke-width="2" fill="none" fill-rule="evenodd"><path d="M13 7L7 1 1 7M1 17l6 6 6-6"/></g></svg>'),
    linear-gradient(-180deg, #fff, #f5f5fa);
  border-color: #efeff5;
  box-shadow: none;
  color: #b6b7d5;
  cursor: not-allowed;
}
.ais-Panel {
  margin-bottom: 2rem;
}
.ais-Panel-header {
  color: #5a5e9a;
  font-size: 0.75rem;
  font-weight: 600;
  line-height: 1rem;
  margin-bottom: 1rem;
  text-transform: uppercase;
}
.ais-Panel-footer {
  color: #5a5e9a;
  font-size: 0.75rem;
  font-weight: 600;
  line-height: 1rem;
  margin-top: 1rem;
}
.ais-Panel--collapsible {
  position: relative;
}
.ais-Panel--collapsible .ais-Panel-collapseButton {
  background: none;
  border: none;
  padding: 0;
  position: absolute;
  right: 0;
  top: 0;
}
.ais-Panel--collapsed .ais-Panel-body,
.ais-Panel--collapsed .ais-Panel-footer {
  display: none;
}
.ais-SearchBox-form {
  background-color: #fff;
  display: flex;
  font-size: 0.875rem;
  /* height: 2.5rem; */
  line-height: 1.25rem;
  position: relative;
  width: 100%;
  border-radius: 9999px;
}
.ais-SearchBox-form::before {
  /* background: #21487d url(../../../public/icons/Union.svg) no-repeat center
    center; */
  content: '';
  height: 45px;
  /* left: 1rem; */
  margin-top: -0.5rem;
  position: absolute;
  top: 22%;
  width: 45px;
  right: 0.5%;
  border-radius: 9999px;
  color: #fff;
  background-size: 40%;
}
.ais-SearchBox-input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: #fff;
  border: 1px solid #d6d6e7;
  border-radius: 9999px;
  border-color: #fff;
  /* box-shadow: rgba(119, 122, 175, 0.3) 0 1px 4px 0 inset; */
  caret-color: #5a5e9a;
  color: #23263b;
  flex: 1 1 0%;
  font: inherit;
  max-width: 100%;
  padding-left: 2.5rem;
}
.ais-SearchBox-input::-moz-placeholder {
  color: #004577;
}
.ais-SearchBox-input::placeholder {
  color: #004577;
}
.search_results .ais-SearchBox-input::-moz-placeholder {
  color: theme('colors.brand.grayfacet');
}
.search_results .ais-SearchBox-input::placeholder {
  color: theme('colors.brand.grayfacet');
}
.ais-SearchBox-input:focus {
  border-color: #3c4fe0;
  box-shadow: rgba(35, 38, 59, 0.05) 0 1px 0 0;
  outline: currentcolor none medium;
}
.ais-SearchBox-input:disabled {
  background: rgba(0, 0, 0, 0) linear-gradient(-180deg, #fff, #f5f5fa) repeat
    scroll 0% 0%;
  border-color: #efeff5;
  box-shadow: none;
  cursor: not-allowed;
}
.ais-SearchBox-input:disabled::-moz-placeholder {
  color: #b6b7d5;
  pointer-events: none;
}
.ais-SearchBox-input:disabled::placeholder {
  color: #b6b7d5;
  pointer-events: none;
}
.ais-SearchBox-input::-webkit-search-cancel-button,
.ais-SearchBox-input::-webkit-search-decoration,
.ais-SearchBox-input::-webkit-search-results-button,
.ais-SearchBox-input::-webkit-search-results-decoration {
  -webkit-appearance: none;
  appearance: none;
}
.ais-SearchBox-loadingIndicator,
.ais-SearchBox-reset {
  align-items: center;
  border-radius: 50%;
  display: flex;
  fill: #484c7a;
  height: 20px;
  justify-content: center;
  position: absolute;
  right: 3.5rem;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
}
.ais-SearchBox-reset:focus {
  background: #b6b7d5;
  fill: #fff;
  outline: 0;
}
.ais-RefinementList-searchBox .ais-SearchBox-loadingIndicator,
.ais-RefinementList-searchBox .ais-SearchBox-reset {
  right: 0.5rem;
}
.ais-SearchBox-loadingIndicator[hidden],
.ais-SearchBox-reset[hidden] {
  display: none;
}
.ais-SearchBox-submit {
  background: #21487d url(../../../public/icons/Union.svg) no-repeat center
    center;
  content: '';
  height: 45px;
  /* left: 1rem; */
  margin-top: -0.5rem;
  position: absolute;
  top: 22%;
  width: 45px;
  right: 0.5%;
  border-radius: 9999px;
  color: #fff;
  background-size: 40%;
  cursor: pointer;
}
.search_results .ais-SearchBox-submit {
  background: white url(../../../public/icons/search_icon.svg) no-repeat center
    center;
  content: '';
  height: 50px;
  margin-top: -0.5rem;
  position: absolute;
  top: 16%;
  width: 45px;
  right: 0;
  border: 1px solid theme('colors.grey.50');
  border-radius: 6px;
  color: #fff;
  background-size: 40%;
  cursor: pointer;
}
.search_results .ais-SearchBox-input:focus {
  border: none;
  border-width: 0;
  box-shadow: none;
  outline: none;
}
.ais-SearchBox-submitIcon {
  display: none;
}
.ais-SearchBox-reset {
  display: flex;
}
.ais-Menu-searchBox,
.ais-RefinementList-searchBox {
  margin-bottom: 0.5rem;
}
.ais-Menu-searchBox .ais-SearchBox-form,
.ais-RefinementList-searchBox .ais-SearchBox-form {
  height: 2rem;
}
.ais-Menu-searchBox .ais-SearchBox-form::before,
.ais-RefinementList-searchBox .ais-SearchBox-form::before {
  left: 0.5rem;
}
.ais-Menu-searchBox .ais-SearchBox-input,
.ais-RefinementList-searchBox .ais-SearchBox-input {
  padding-left: 2rem;
}
.ais-VoiceSearch-button {
  color: #5a5e9a;
  height: 48px;
  width: 48px;
}
.ais-VoiceSearch-button svg {
  color: currentcolor;
}
.ais-Highlight-highlighted,
.ais-Snippet-highlighted {
  background-color: rgba(84, 104, 255, 0.1);
  color: #5468ff;
  font-style: normal;
}
.ais-ReverseHighlight-highlighted,
.ais-ReverseSnippet-highlighted {
  font-weight: bold;
  font-style: normal;
  background: none;
}
.search_results .ais-Hits-item {
  min-width: 300px;
}
.ais-Hits-item,
.ais-InfiniteHits-item {
  align-items: center;
  /* background: #fff;
  box-shadow: 0 0 0 1px rgba(35, 38, 59, 0.05),
    0 1px 3px 0 rgba(35, 38, 59, 0.15); */
  display: flex;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.25rem;
  /* padding: 1.5rem; */
}
.ais-Hits-item:first-of-type,
.ais-InfiniteHits-item:first-of-type {
  border-radius: 3px 3px 0 0;
}
.ais-Hits-item:last-of-type,
.ais-InfiniteHits-item:last-of-type {
  border-radius: 0 0 3px 3px;
}
.ais-Hits-item:only-of-type,
.ais-InfiniteHits-item:only-of-type {
  border-radius: 3px;
}
.ais-InfiniteHits-loadMore,
.ais-InfiniteHits-loadPrevious {
  display: flex;
  margin: 1rem auto;
}
.ais-GeoSearch {
  position: relative;
}
.ais-GeoSearch-control {
  left: 3.75rem;
  position: absolute;
  top: 1rem;
}
.ais-GeoSearch-label {
  align-items: center;
  background-color: #f5f5fa;
  border: 1px solid #b6b7d5;
  border-radius: 3px;
  cursor: pointer;
  display: inline-flex;
  font-size: 0.875rem;
  line-height: 1.5;
  min-height: 1.5rem;
  padding: 0.25rem 0.5rem;
}
.ais-GeoSearch-label,
.ais-GeoSearch-redo,
.ais-GeoSearch-reset {
  white-space: nowrap;
}
.ais-GeoSearch-reset {
  bottom: 1.25rem;
  left: 50%;
  position: absolute;
  transform: translateX(-50%);
}
.ais-RefinementList-checkbox,
.ais-GeoSearch-input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: #fff;
  background-position: 50%;
  background-size: 180%;
  border: 1px solid currentcolor;
  border-radius: 3px;
  box-shadow: inset 0 1px 4px 0 rgba(119, 122, 175, 0.4);
  color: #d6d6e7;
  cursor: inherit;
  height: 1rem;
  margin: 0 0.5rem 0 0;
  min-width: 1rem;
}
.ais-RefinementList-item--selected .ais-RefinementList-checkbox,
.ais-GeoSearch-input:checked {
  background-image: url('data:image/svg+xml;utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2024%2024%22%20fill%3D%22none%22%20stroke%3D%22%235468ff%22%20stroke-width%3D%222%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%3E%3Cpolyline%20points%3D%2220%206%209%2017%204%2012%22%3E%3C%2Fpolyline%3E%3C%2Fsvg%3E');
  background-size: 14px;
  border-color: currentcolor;
  box-shadow: rgba(35, 38, 59, 0.05) 0 1px 0 0 inset;
  color: #3c4fe0;
}
.ais-RefinementList-checkbox:focus,
.ais-GeoSearch-input:focus {
  outline: currentcolor none medium;
}
.ais-RefinementList-item--selected .ais-RefinementList-checkbox:focus,
.ais-GeoSearch-input:checked:focus {
  box-shadow: rgba(35, 38, 59, 0.05) 0 1px 0 0 inset, currentcolor 0 0 0 1px;
}
.ais-NumericMenu-label {
  align-items: center;
  cursor: pointer;
  display: flex;
}
.ais-NumericMenu-radio {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: #fff;
  border: 1px solid #d6d6e7;
  border-radius: 50%;
  box-shadow: inset 0 1px 4px 0 rgba(119, 122, 175, 0.3);
  height: 16px;
  margin: 0 0.5rem 0 0;
  outline: 0;
  position: relative;
  width: 16px;
}
.ais-NumericMenu-radio:checked,
.ais-NumericMenu-radio:focus {
  border-color: #3c4fe0;
  box-shadow: 0 1px 0 0 rgba(35, 38, 59, 0.05);
}
.ais-NumericMenu-radio:focus {
  box-shadow: 0 0 0 1px #3c4fe0, 0 1px 0 0 rgba(35, 38, 59, 0.05);
}
.ais-NumericMenu-radio:checked:after {
  background: #3c4fe0;
  border-radius: 50%;
  bottom: 4px;
  content: '';
  left: 4px;
  position: absolute;
  right: 4px;
  top: 4px;
}
.ais-HierarchicalMenu-list .ais-HierarchicalMenu-list {
  margin-left: 1.5rem;
}
.ais-HierarchicalMenu-link::before {
  background-image: url('data:image/svg+xml;utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2024%2024%22%20fill%3D%22none%22%20stroke%3D%22%23b6b7d5%22%20stroke-width%3D%222%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%3E%3Cpolyline%20points%3D%2218%2015%2012%209%206%2015%22%3E%3C%2Fpolyline%3E%3C%2Fsvg%3E');
  background-repeat: no-repeat;
  content: '';
  display: inline-block;
  height: 1rem;
  margin-right: 0.5rem;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  width: 1rem;
}
.ais-HierarchicalMenu-item--selected > .ais-HierarchicalMenu-link::before {
  background-image: url('data:image/svg+xml;utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2024%2024%22%20fill%3D%22none%22%20stroke%3D%22%235468ff%22%20stroke-width%3D%222%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%3E%3Cpolyline%20points%3D%226%209%2012%2015%2018%209%22%3E%3C%2Fpolyline%3E%3C%2Fsvg%3E');
}
.ais-RatingMenu-starIcon {
  margin-right: 0.25rem;
}
.ais-RatingMenu-starIcon {
  fill: #5a5e9a;
  position: relative;
  width: 16px;
}
.ais-RatingMenu-item--disabled .ais-RatingMenu-starIcon {
  fill: #b6b7d5;
}
.ais-RatingMenu-item--disabled .ais-RatingMenu-count,
.ais-RatingMenu-item--disabled .ais-RatingMenu-label {
  color: #b6b7d5;
}
.ais-ClearRefinements {
  display: inline;
}
.ais-ClearRefinements-button {
  background-color: theme('colors.brand.lightish');
  border: 1px solid theme('colors.brand.lightmeh');
  border-radius: 43px;
  padding: 24px;
  display: flex;
  display: inline-flex;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  color: black;
  margin-left: 1rem;
}
.ais-CurrentRefinements {
  display: inline;
}
.ais-CurrentRefinements-list {
  display: inline-grid;
  gap: 1rem;
  grid-auto-flow: column;
}
.ais-CurrentRefinements-label {
  text-transform: capitalize;
}
.ais-CurrentRefinements-item {
  align-items: center;
  background-color: theme('colors.brand.lightish');
  border: 1px solid theme('colors.brand.lightmeh');
  border-radius: 43px;
  display: flex;
  display: inline-flex;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  padding: 12px;
  color: black;
}
.ais-CurrentRefinements-category {
  display: flex;
  margin-left: 0.5rem;
}
.ais-CurrentRefinements-delete {
  color: #9698c3;
  height: 100%;
  margin-left: 0.25rem;
  outline-width: 0;
}
.ais-ToggleRefinement-label {
  align-items: center;
  cursor: pointer;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
}
.ais-ToggleRefinement-checkbox {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: rgba(65, 66, 71, 0.08);
  background: #f5f5fa;
  border-radius: 9999px;
  box-shadow: inset 0 1px 4px 0 rgba(119, 122, 175, 0.4);
  height: 18px;
  pointer-events: none;
  position: relative;
  width: 32px;
}
.ais-ToggleRefinement-checkbox:checked {
  background: #3c4fe0;
  box-shadow: inset 0 1px 4px 0 rgba(35, 38, 59, 0.1);
}
.ais-ToggleRefinement-checkbox:focus {
  outline: 0;
}
.ais-ToggleRefinement-checkbox::after {
  background: linear-gradient(-180deg, #fff, #f5f5fa);
  border-radius: 9999px;
  box-shadow: 1px 1px 1px 0 rgba(35, 38, 59, 0.05);
  content: '';
  height: 16px;
  left: 1px;
  position: absolute;
  top: 1px;
  transition: all 100ms ease-in-out;
  width: 16px;
}
.ais-ToggleRefinement-checkbox:checked::after {
  transform: translateX(14px);
}
.ais-RangeInput-input {
  align-items: center;
  background-color: #fff;
  border: 1px solid #d6d6e7;
  border-radius: 3px;
  box-shadow: inset 0 1px 4px 0 rgba(119, 122, 175, 0.3);
  caret-color: #5a5e9a;
  color: #23263b;
  cursor: text;
  display: flex;
  flex: 1;
  font-size: 0.875rem;
  height: 2rem;
  line-height: 1.25rem;
  min-width: 0;
  overflow: hidden;
  padding: 0 1rem;
  position: relative;
}
.ais-RangeInput-input:focus {
  border-color: #3c4fe0;
  box-shadow: 0 1px 0 0 rgba(35, 38, 59, 0.05);
  outline: 0;
}
.ais-RangeInput-separator {
  margin: 0 0.5rem;
}
.ais-RangeInput-submit {
  margin-left: 0.5rem;
}
.ais-RangeSlider .rheostat {
  margin: 40px 6px;
}
.ais-RangeSlider .rheostat-horizontal {
  cursor: pointer;
  width: calc(100% - 15px);
}
.ais-RangeSlider .rheostat-background {
  background-color: rgba(0, 0, 0, 0);
  border: none;
  border-radius: 3px;
  box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.1),
    0 0.5px 0 0 rgba(255, 255, 255, 0.05);
  height: 4px;
}
.ais-RangeSlider .rheostat-progress {
  background-color: #3c4fe0;
  border-radius: 3px;
  height: 4px;
  max-width: 100%;
  top: 0;
}
.ais-RangeSlider .rheostat-tooltip {
  font-weight: bold;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}
.ais-RangeSlider .rheostat-handle {
  background-color: #fff;
  background-image: linear-gradient(-180deg, #fff, #fcfcfd);
  border: 1px solid #d6d6e7;
  border-radius: 2px;
  box-shadow: 0 1px 0 0 rgba(35, 38, 59, 0.05);
  height: 24px;
  margin-left: -6px;
  top: -11px;
  width: 12px;
}
.ais-RangeSlider .rheostat-handle::before,
.ais-RangeSlider .rheostat-handle::after {
  background-color: #d6d6e7;
  content: '';
  height: 12px;
  position: absolute;
  top: 6px;
  width: 1px;
}
.ais-RangeSlider .rheostat-handle::before {
  left: 4px;
}
.ais-RangeSlider .rheostat-handle::after {
  right: 4px;
}
.ais-RangeSlider .rheostat-marker {
  background-color: #d6d6e7;
}
.ais-Pagination-item:first-child > .ais-Pagination-link {
  border-radius: 3px 0 0 3px;
}
.ais-Pagination-item + .ais-Pagination-item > .ais-Pagination-link {
  border-radius: 0;
  margin-left: -1px;
}
.ais-Pagination-item:last-child > .ais-Pagination-link {
  border-radius: 0 3px 3px 0;
}
.ais-Pagination-item {
  display: inline-block;
}
.ais-Pagination-item--selected .ais-Pagination-link {
  font-weight: bold;
}
.ais-Breadcrumb-link,
.ais-Breadcrumb-separator {
  color: #5a5e9a;
}
.ais-Breadcrumb-separator {
  display: flex;
  margin: 0 0.5rem;
}
.ais-Breadcrumb-item--selected {
  font-weight: 600;
}
.ais-Breadcrumb-item--selected .ais-Breadcrumb-separator {
  font-weight: normal;
}
@media (min-width: 371px) and (max-width: 767px) {
  .ais-SearchBox-input,
  .ais-RangeInput-input {
    font-size: 0.75rem;
    padding-left: 0.5rem !important;
    color: #004577;
  }
  .ais-SearchBox-form {
    height: 2rem;
  }
  .search_results .ais-SearchBox-form {
    height: 2.5rem;
  }
  .search_results .ais-SearchBox-submit {
    height: 40px;
    top: 20%;
  }
  .ais-SearchBox-form::before {
    height: 28px;
    width: 28px;
    top: 31%;
    right: 1%;
  }
  .ais-SearchBox-submit {
    height: 28px;
    width: 28px;
    top: 31%;
    right: 1%;
  }
  .ais-SearchBox-reset {
    right: 2rem;
  }
  .search_results .ais-SearchBox-reset {
    right: 3rem;
  }
}
@media (max-width: 370px) {
  .ais-SearchBox-input,
  .ais-RangeInput-input {
    font-size: 0.5rem !important;
    padding-left: 0.5rem !important;
    color: #004577;
  }
  .ais-SearchBox-form {
    height: 2rem;
  }
  .ais-SearchBox-form::before {
    height: 28px;
    width: 28px;
    top: 31%;
    right: 1%;
  }
  .ais-SearchBox-submit {
    height: 28px;
    width: 28px;
    top: 31%;
    right: 1%;
  }
  .ais-SearchBox-reset {
    right: 2rem;
  }
}

.ais-Stats-text {
  text-transform: capitalize;
}

@media screen and (max-width: 480px) {
  .ais-ClearRefinements-button {
    padding: 20px;
    margin: 0 0.5rem;
  }
  .ais-Hits-item,
  .ais-InfiniteHits-item,
  .ais-Hits-item:first-of-type,
  .ais-InfiniteHits-item:first-of-type,
  .ais-Hits-item:last-of-type,
  .ais-InfiniteHits-item:last-of-type {
    margin: 5px 0;
    width: 50%;
    padding: 0 2px;
  }
  .ais-Stats-text {
    font-size: 16px;
    line-height: 19.36px;
  }
}

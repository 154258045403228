@tailwind base;
@tailwind components;
@tailwind utilities;

/* Write your own custom base styles here */
@import 'scss/product-card';
@import 'scss/iphones.scss';

@font-face {
  font-family: 'Sofia Pro';
  src: url('/assets/fonts/Sofia-Pro-Regular-Az.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Sofia Pro';
  src: url('/assets/fonts/Sofia-Pro-Regular-Italic-Az.woff') format('woff');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Sofia Pro';
  src: url('/assets/fonts/Sofia-Pro-Medium-Az.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Sofia Pro';
  src: url('/assets/fonts/Sofia-Pro-Medium-Italic-Az.woff') format('woff');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Sofia Pro';
  src: url('/assets/fonts/Sofia-Pro-Semi-Bold-Az.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Sofia Pro';
  src: url('/assets/fonts/Sofia-Pro-Semi-Bold-Italic-Az.woff') format('woff');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'Sofia Pro';
  src: url('/assets/fonts/Sofia-Pro-Bold-Az.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Sofia Pro';
  src: url('/assets/fonts/Sofia-Pro-Bold-Italic-Az.woff') format('woff');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'Sofia Pro';
  src: url('/assets/fonts/Sofia-Pro-Black-Az.woff') format('woff');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Sofia Pro';
  src: url('/assets/fonts/Sofia-Pro-Black-Italic-Az.woff') format('woff');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'Blog Script';
  src: url('/assets/fonts/Blog-Script.ttf') format('truetype');
}

/* Add other weights as needed */

// @font-face {
//   font-family: 'Sofia Pro';
//   src: local('Sofia Pro Regular'),
//     url('/assets/fonts/Sofia-Pro-Regular-Az.woff') format('woff'),
//     local('Sofia Pro Regular Italic'),
//     url('/assets/fonts/Sofia-Pro-Regular-Italic-Az.woff') format('woff'),
//     local('Sofia Pro ExtraLight'),
//     url('/assets/fonts/Sofia-Pro-ExtraLight-Az.woff') format('woff'),
//     local('Sofia Pro UltraLight'),
//     url('/assets/fonts/Sofia-Pro-UltraLight-Az.woff') format('woff'),
//     local('Sofia Pro ExtraLight Italic'),
//     url('/assets/fonts/Sofia-Pro-ExtraLight-Italic-Az.woff') format('woff'),
//     local('Sofia Pro UltraLight Italic'),
//     url('/assets/fonts/Sofia-Pro-UltraLight-Italic-Az.woff') format('woff'),
//     local('Sofia Pro Light'),
//     url('/assets/fonts/Sofia-Pro-Light-Az.woff') format('woff'),
//     local('Sofia Pro Light Italic'),
//     url('/assets/fonts/Sofia-Pro-Light-Italic-Az.woff') format('woff'),
//     local('Sofia Pro Medium'),
//     url('/assets/fonts/Sofia-Pro-Medium-Az.woff') format('woff'),
//     local('Sofia Pro Medium Italic'),
//     url('/assets/fonts/Sofia-Pro-Medium-Italic-Az.woff') format('woff'),
//     local('Sofia Pro SemiBold'),
//     url('/assets/fonts/Sofia-Pro-Semi-Bold-Az.woff') format('woff'),
//     local('Sofia Pro SemiBold Italic'),
//     url('/assets/fonts/Sofia-Pro-Semi-Bold-Italic-Az.woff') format('woff'),
//     local('Sofia Pro Bold'),
//     url('/assets/fonts/Sofia-Pro-Bold-Az.woff') format('woff'),
//     local('Sofia Pro Bold Italic'),
//     url('/assets/fonts/Sofia-Pro-Bold-Italic-Az.woff') format('woff'),
//     local('Sofia Pro Black'),
//     url('/assets/fonts/Sofia-Pro-Black-Az.woff') format('woff'),
//     local('Sofia Pro Black Italic'),
//     url('/assets/fonts/Sofia-Pro-Black-Italic-Az.woff') format('woff');
//   font-weight: 100 900; /* Specifies the range of weights available */
//   font-style: normal, italic, bold; /* Specifies the styles available */
// }

@font-face {
  font-family: 'Digital Numbers';
  src: url('/assets/fonts/Digital-Numbers-Regular.ttf') format('truetype');

  font-weight: 100 900; /* Specifies the range of weights available */
  font-style: normal, italic, bold; /* Specifies the styles available */
}

.font-sofia-pro {
  font-family: 'Sofia Pro', sans-serif;
}

.font-blogscript {
  font-family: 'Blog Script', sans-serif;
}

.font-digital-numbers {
  font-family: 'Digital Numbers', sans-serif;
}

.font-poppins {
  font-family: 'Poppins', sans-serif;
}

@layer base {
  body {
    font-family: 'Inter', sans-serif;
    background-color: #ffffff;
  }

  html {
    -webkit-tap-highlight-color: transparent;
  }

  html,
  body {
    @apply antialiased bg-brand-light text-brand-muted font-body;
  }

  /* p {
    @apply mb-5;
  } */

  // p:last-of-type {
  //   @apply mb-0;
  // }

  p > strong {
    @apply font-semibold;
  }

  p > a {
    @apply transition;
  }

  /* p > a:hover {
    @apply text-brand-dark;
  } */

  .h-inherit {
    height: inherit;
  }

  .order-list-enable ol {
    list-style: decimal;
    @apply ltr:pl-4 rtl:pr-4 lg:ltr:pl-8 lg:rtl:pr-8 space-y-1.5;
  }

  button {
    padding: 0; /* Reset padding */
    margin: 0; /* Reset margin */
    border: none; /* Remove default border */
    background: none; /* Remove background */
    appearance: none; /* Remove default button styling */
  }

  button:focus {
    outline: 0px solid !important;
  }
}

/* Header Menu */
#siteHeader.is-scrolling .innerSticky {
  @apply top-0 transition duration-200 ease-in-out shadow-header;
}

#siteHeader.header-four.is-scrolling .innerSticky {
  @apply lg:pb-1.5;
  background-color: white !important;
}

.subMenu {
  top: calc(100% + 21px);
}

/* for header one */
.header-one .headerMenu .menuItem > a::before {
  bottom: -22px;
}

.subMenu li:hover > .subMenuChild {
  @apply visible opacity-100 -top-3;
}

.headerMenu .menuItem:hover > .subMenu {
  visibility: visible;
  top: calc(100% + 2px);
}

.header-one .headerMenu .menuItem:hover > .subMenu {
  top: calc(100% + 10px);
}

/* Mobile Drawer Menu */
.mobile-menu > li > div > a {
  @apply text-15px py-3.5 font-medium;
}

.mobile-menu li li {
  @apply text-sm;
}

.mobile-menu li li div > a {
  @apply relative py-2.5 text-opacity-90;
}

.mobile-menu li > ul > li > div > a:before {
  content: '';
  height: 1px;
  @apply flex absolute top-1/2 ltr:left-1 rtl:right-1 md:ltr:left-3 md:rtl:right-3 w-[7px] bg-brand-dark;
}

/* For login and signup switch */
.switch {
  height: 22px;
}

.slider:before {
  position: absolute;
  content: '';
  height: 16px;
  width: 16px;
  left: 4px;
  bottom: 3px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

main input:checked + .slider {
  background-color: #e5e9e8;
}

input:checked + .slider:before {
  -webkit-transform: translateX(16px);
  -ms-transform: translateX(16px);
  transform: translateX(16px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 20px;
}

.slider.round:before {
  border-radius: 50%;
}

/* Others Component */
.product-count-button-position {
  @apply absolute bottom-3 lg:bottom-2 w-full ltr:left-1 rtl:right-1 flex justify-center px-2.5 lg:px-3 xl:px-4;
}

.product-count-button-position > button {
  @apply ltr:ml-auto rtl:mr-auto;
}

.product-count-button-position > div {
  @apply w-[calc(100%-28px)] xl:w-[calc(100%-50px)];
}

.after-item-opacity:after {
  @apply w-[16%] 2xl:w-[14%] 3xl:w-[12%] 4xl:hidden;
}

@media (min-width: 1280px) {
  .after-item-opacity:after {
    @apply absolute top-0 h-full ltr:right-0 rtl:left-0;
    background-image: linear-gradient(
      to right,
      rgba(255, 255, 255, 0.2),
      rgba(255, 255, 255, 0.9),
      rgba(255, 255, 255, 1)
    );
    content: '';
    z-index: 5;
  }
}

.overlay {
  background-color: rgba(0, 0, 0, 0.35);
  z-index: 21;
}

.overlay.open,
.overlay.input-focus-overlay-open,
.overlay.open-search-overlay,
.category-dropdown-menu > li:hover > div {
  opacity: 1;
  visibility: visible;
}

.bg-progress-striped {
  background-image: linear-gradient(
    -45deg,
    rgba(255, 255, 255, 0.3) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.3) 50%,
    rgba(255, 255, 255, 0.3) 75%,
    transparent 75%,
    transparent
  );
  background-size: 14px 14px;
}

.scroll-snap-type-y-mandatory {
  scroll-snap-type: y mandatory;
}

.scroll-snap-align-start {
  scroll-snap-align: start;
}

.faq-banner-area {
  background-position: 20% 0;
}

@media (min-width: 768px) {
  .faq-banner-area {
    background-position: center center;
  }
}

@media (min-width: 1024px) {
  .minimal-main-content {
    width: calc(100% - 320px);
  }
}

@media (min-width: 1280px) {
  .minimal-main-content {
    width: calc(100% - 370px);
  }

  .trendy-main-content {
    width: calc(100% - 320px);
  }
}

@media (min-width: 1536px) {
  .trendy-main-content {
    width: calc(100% - 370px);
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .hero-banner-six {
    background-position: 15% center;
  }

  .page-header-banner {
    background-position: 17% center;
  }
}

@media (min-width: 1024px) and (max-width: 1279px) {
  .hero-banner-six {
    background-position: 42% center;
  }

  .page-header-banner {
    background-position: 80% center;
  }
}

@media (min-width: 1280px) and (max-width: 1535px) {
  .hero-banner-six {
    background-position: 35% center;
  }
}

/* Breadcrumb truncate last child */
.borobazarBreadcrumb li:last-child {
  @apply font-medium truncate text-brand-dark;
}

.style-variant-white .borobazarBreadcrumb ol > li > a > svg,
.style-variant-white .borobazarBreadcrumb ol > li > a {
  @apply text-brand-light text-opacity-70;
}

.style-variant-white .borobazarBreadcrumb ol > li > svg {
  @apply text-opacity-50 text-brand-light;
}

.style-variant-white .borobazarBreadcrumb ol > li:last-of-type > a {
  @apply text-brand-light;
}

.header-two.sticky-header.is-scrolling .navbar-logo,
.header-five.sticky-header.is-scrolling .navbar-logo {
  @apply -mt-1;
}

.header-five.sticky-header.is-scrolling .innerSticky .categories-header-button {
  display: none;
}

/* sticky site header styles */
@media (min-width: 1024px) {
  .sticky-header.is-scrolling .navbar {
    @apply fixed top-0 z-30 w-full shadow-header;
  }

  .sticky-header.is-scrolling .delivery-address,
  .sticky-header .navbar .sticky-search {
    @apply hidden;
  }

  .sticky-header.is-scrolling .navbar .sticky-search {
    @apply flex;
  }

  .sticky-header .navbar-logo,
  .sticky-header .navbar .navbar-right {
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0);
  }

  .sticky-header.is-scrolling .navbar-logo,
  .sticky-header.is-scrolling .navbar .navbar-right {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    @apply w-auto opacity-100;
  }

  .header-two.sticky-header.is-scrolling .headerMenu,
  .header-five.sticky-header.is-scrolling .headerMenu {
    @apply ltr:ml-8 rtl:mr-8;
  }
}

#siteHeader .innerSticky .top-bar-search {
  @apply hidden;
}

.sticky-header .top-bar.active-mobile-search .logo {
  @apply hidden;
}

#siteHeader.active-mobile-search .innerSticky .top-bar-search {
  @apply block;
}

.subscribe-btn:hover {
  box-shadow: none !important;
}

.registration img {
  height: 100%;
  object-fit: cover;
}

/* Order page */
/* order list table */
.order-list-table table th,
.order-list-table table td {
  border: none;
}

.order-list-table.rc-table thead td,
.order-list-table.rc-table thead th {
  text-align: left;
}

.order-list-table .rc-table-content {
  border-color: transparent;
}

.rc-table-content {
  overflow: visible !important;
}

.order-list-table.rc-table .rc-table-thead {
  background: #f3f6f9;
  border-radius: 3px;
  opacity: 1;
}

.order-list-table.rc-table thead th {
  font-weight: 500;
  color: rgba(0, 0, 0, 0.5);
}

.order-list-table.rc-table th,
.order-list-table.rc-table td {
  padding: 9px 18px;
  color: rgba(0, 0, 0, 1);
  font-size: 14px;
}

.order-list-table.rc-table thead th {
  padding-top: 12px;
  padding-bottom: 12px;
}

.order-list-table.rc-table td {
  border-bottom: 1px solid #e7ecf0;
}

.order-list-table.rc-table tr td {
  transition: background-color 0.5s;
}

.order-list-table.rc-table tr:hover td {
  background-color: #f3f6f9;
}

.bullet {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 6px;
}

.actions_button_group svg {
  width: 100%;
}

@media (max-width: 767px) {
  .order-list-table-wraper {
    position: relative;
    overflow-x: auto;
  }

  .order-list-table.rc-table th:not(.operations-cell),
  .order-list-table.rc-table td:not(.operations-cell) {
    white-space: nowrap;
  }

  .order-list-table.rc-table th:first-child,
  .order-list-table.rc-table td:first-child {
    position: sticky;
    left: 0;
    background-color: #fff;
    z-index: 1;
  }

  .order-list-table.rc-table th.id-cell {
    background: #f3f6f9;
  }

  .order-list-table.rc-table td.operations-cell {
    padding: 0;
  }
  .searched-products .product-count-button-position > div {
    width: 100%;
    justify-content: space-around;
    height: 30px;
  }
  .searched-products .product-count-button-position .counter-btn-1 {
    padding-left: 15px;
  }
  .searched-products .product-count-button-position .counter-btn-2 {
    padding-right: 15px !important;
  }
}

/* Address box */

.address__box input[type='radio']:checked ~ label {
  border-color: #02b290;
}

@media (min-width: 1024px) {
  .address__box:hover .address__actions {
    opacity: 1;
  }
}

/* Checkout details page accordion */
.accordion__panel.collapsed .accordion__content {
  display: none;
}

.accordion__panel.expanded .accordion__content {
  display: block;
  background-color: #ffffff;
}

.accordion__panel.collapsed .accordion__button {
  background-color: #f8f9fa;
}

.address__box:hover .address__actions {
  opacity: 1;
}

.borderColor::before {
  content: '';
  position: absolute;
  left: 2px;
  width: 3px;
  background: #02b290;
  @apply h-6 top-3 2xl:top-2.5;
}

.registration > div {
  height: 100%;
}

.StripeElement {
  box-sizing: border-box;
  height: 50px;
  padding: 15px;
  border: 1px solid #e2e8f0;
  border-radius: 4px;
  background-color: white;
  -webkit-transition: border 150ms ease;
  transition: border 150ms ease;
}

.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}

.scrollbar {
  scrollbar-width: none;
  -webkit-scrollbar-width: none;
}

.scrollbar::-webkit-scrollbar {
  display: none;
  /* Safari and Chrome */
}

.wishlist-card:last-child {
  border-bottom: none;
}

.order-search {
  @apply bg-white #{!important};
  @apply rounded-md #{!important};
}

.order-icon-color {
  color: rgba(0, 0, 0, 0.3);
  height: 100%;
}

.checkout .save-change-button {
  display: none;
}

/* FSD custom */
.ais-SearchBox-form::before {
  right: 1rem;
  left: auto;
}

.ais-Hits-item,
.ais-InfiniteHits-item {
  padding: 0;
}

.ais-Hits-list {
  display: flex;
  flex-wrap: wrap;
}

.ais-Hits-item,
.ais-InfiniteHits-item {
  width: 200px;
  margin: 10px;
}

ais-highlight-0000000000 {
  color: #21487d;
  font-weight: bold;
  background-color: lightcyan;
}

.ais-Highlight-highlighted,
.ais-Snippet-highlighted {
  color: theme('colors.brand.black') !important;
  font-weight: bold !important;
  background-color: white !important;
}

.ais-SearchBox-form {
  height: 50px;
  background: none;
}

.ais-InfiniteHits-loadMore:disabled[disabled] {
  display: none;
}

.ais-InfiniteHits-list {
  justify-content: stretch;
  justify-self: flex-start;
  align-items: stretch;
}

label#headerSearchLabel,
label#subscriptionFooterLabel {
  display: none;
}

.ais-SearchBox-form::before {
  background: none;
}

.ais-SearchBox-input {
  border-radius: 5px;
  padding-left: 1.5rem !important;
}

.search-results {
  position: fixed;
  width: 1310px;
  top: 73px;
  left: 3%;
  background-color: #fff;
  border-width: 0 1px 1px;
  border-radius: 0 0 5px 5px;
  border-color: #000;
  .searchViewMore {
    background-color: theme('colors.brand.DEFAULT');
  }
  .ais-Hits-list .ais-Hits-item:nth-child(11),
  .ais-Hits-list .ais-Hits-item:nth-child(12) {
    display: none;
  }
}

@media (max-width: 1280px) {
  .search-results {
    left: 0;
    top: 60px;
    width: calc(100% - 3px);
    .ais-Hits-item,
    .ais-InfiniteHits-item,
    .ais-Hits-item:first-of-type,
    .ais-InfiniteHits-item:first-of-type,
    .ais-Hits-item:last-of-type,
    .ais-InfiniteHits-item:last-of-type {
      width: calc(33% - 5px);
      margin: 0;
      padding: 6px 3px;
    }
    .ais-Hits-list .ais-Hits-item:nth-child(11),
    .ais-Hits-list .ais-Hits-item:nth-child(12) {
      display: flex;
    }
  }
}
@media (max-width: 768px) {
  .search-results {
    .ais-Hits-item,
    .ais-InfiniteHits-item,
    .ais-Hits-item:first-of-type,
    .ais-InfiniteHits-item:first-of-type,
    .ais-Hits-item:last-of-type,
    .ais-InfiniteHits-item:last-of-type {
      width: calc(100% - 5px);
    }
  }
}

.search-results > ul {
  float: left;
  min-width: 210px;
  border: solid 1px #ccc;
  border-radius: 5px;
  padding: 3px;
  background-color: #fff;
  margin-top: 5px;
  display: none;
}

.search-results > ul li {
  white-space: nowrap;
}

@media (min-width: 768px) {
  .search-results > ul {
    display: inline;
  }
}

.search-results > ul .count {
  float: right;
}

.search-results > ul .name {
  color: #21487d;
}

.search-results .ais-Stats {
  color: #999;
  font-size: smaller;
}

.search-results h5 {
  font-weight: bold;
  margin: 5px 0;
}

.ais-Hits-item,
.ais-InfiniteHits-item,
.ais-Hits-item:first-of-type,
.ais-InfiniteHits-item:first-of-type,
.ais-Hits-item:last-of-type,
.ais-InfiniteHits-item:last-of-type {
  width: 30%;
  margin: 5px;
  border-radius: 5px;
  min-width: 125px;
}

.search_filters {
  display: none;
}

.mobile-facets .search_filters {
  display: inline-block;
  width: 100%;
}

.mobileSearchFacets {
  background-color: theme('colors.brand.darkblue');
  font-family: 'Sofia Pro';
  font-weight: 500;
  margin-top: -10px;
}

@media (min-width: 768px) {
  .ais-Hits-item,
  .ais-InfiniteHits-item,
  .ais-Hits-item:first-of-type,
  .ais-InfiniteHits-item:first-of-type,
  .ais-Hits-item:last-of-type,
  .ais-InfiniteHits-item:last-of-type {
    max-width: 250px;
    min-width: 200px;
  }
  .search_filters {
    width: 300px;
    display: inline-block;
  }
  .search_filters .name {
    text-transform: capitalize;
  }
  .search_filters .count {
    background: #f5f5fa none repeat scroll 0 0;
    border: 1px solid #b6b7d5;
    border-radius: 99999px;
    color: #23263b;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1rem;
    margin-left: 0.5rem;
    overflow: hidden;
    padding: 0 0.25rem;
  }
}

.ais-Hits-item .card {
  width: 100%;
}

.ais-Hits-item .hit-image {
  float: left;
  padding: 0 3px 3px 0;
}

.ais-Hits-item .result-cat {
  color: #999;
  font-size: smaller;
  font-style: italic;
}

.ais-Hits-item .result-name {
  font-weight: bolder;
}

.ais-Pagination {
  float: right;
  margin-right: 5px;
}

.circular-progress-icon {
  animation: spin 2s linear infinite;
  /* Adjust animation duration as needed */
}

#apple-pay-button {
  background-color: black;
  color: white;
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
}

.disabled-input {
  opacity: 0.6;
}
.line-through {
  text-decoration: line-through;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

img.cat_list_card {
  height: fit-content;
}

.search-results .shadow-card {
  box-shadow: none;
}

.order-list-table .rc-table-content table {
  table-layout: auto !important;
}

table {
  table-layout: fixed !important;
  overflow: visible;
}

.faq-content ul,
.faq-content ol {
  list-style: initial;
  margin: initial;
  padding: revert;
}

.bg-fill-thumbnail {
  background-color: white;
}
article .object-cover {
  object-fit: contain;
}

/* Chrome, Safari, Edge, Opera */
.counter-input::-webkit-outer-spin-button,
.counter-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.text-store-credit {
  color: #939393;
}

select {
  background-image: url('/icons/polygon.svg');
  background-size: 12px;
  background-position: right 1.1rem center;
}

.zoom-product:first-child {
  margin-left: 10px;
}

.zoom-product:hover {
  transform: scale(1.13);
  transform-origin: center center;
  // margin-left: 13px;
  // margin-right: 13px;
  transition: 0.2s all ease-in-out;
}

// .zoom-80 {
//   zoom: 80%;
// }

// .zoom-100 {
//   zoom: 100%;
// }

.zoom-responsive {
  @include xs {
    zoom: 49.5%;
  }

  @include sm {
    zoom: 100% !important;
  }
}

.p-product-detail-featured-bullet ul li:before {
  content: '';
  align-items: center;
  justify-content: center;
  line-height: 1em;
  width: 1em;
  height: 1em;
  max-height: 100%;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  background-image: url(../../../public/icons/charm_tick.svg);
  font-size: 18px;
  position: absolute;
  margin-left: -22px;
  margin-top: 6px;
}

.thumbnail-popup .swiper-wrapper {
  z-index: 0 !important;
}

.scrollbar-thin {
  scrollbar-width: thin;
}

@media (min-width: 320px) and (max-width: 1024px) {
  .homePageMenu,
  .headerRightDesktop,
  .shopByDepart {
    display: none;
  }
  .userIcon {
    display: flex;
  }
  .searchNew {
    margin-left: 0 !important;
  }
  .languageSwitcher {
    display: flex;
  }
}

@media (min-width: 1536px) {
  .homePageMenu,
  .headerRightDesktop {
    display: flex;
  }
  .shopByDepart {
    display: block;
  }
}
@media (min-width: 320px) and (max-width: 415px) {
  .data-table-container {
    width: fit-content !important;
  }
}

@media (min-width: 1025px) {
  .userIcon {
    display: none;
  }
  .languageSwitcher {
    display: none;
  }
}

@media (max-width: 478px) {
  .fsdLogoMobile {
    display: flex;
  }
  .fsdLogo {
    display: none;
  }
}

@media (min-width: 479px) {
  .fsdLogoMobile {
    display: none;
  }
  .fsdLogo {
    display: flex;
  }
}
@media (min-width: 751px) and (max-width: 900px) {
  .cartCount {
    right: 4.5%;
    font-size: 8px;
    min-width: 15px;
    min-height: 15px;
    top: 0.5rem;
  }
}
@media (min-width: 901px) and (max-width: 1023px) {
  .cartCount {
    right: 3.5%;
  }
}
@media (min-width: 320px) and (max-width: 750px) {
  .cartCount {
    // right: 4%;
    font-size: 8px;
    min-width: 15px;
    min-height: 15px;
    top: 0.25rem;
  }
}
@media (min-width: 1025px) and (max-width: 1350px) {
  .shopByBrand {
    padding-left: 20px;
  }
}

html {
  scroll-behavior: smooth;
}
@media (min-width: 1025px) and (max-width: 1350px) {
  .shopByBrand {
    padding-left: 20px;
  }
}

.items_might_like {
  .swiper-initialized {
    margin: 0 20px;
    padding-left: 10px;
    padding-bottom: 50px;
  }
}

.zoid-component-frame.zoid-visible {
  z-index: 10 !important;
}

.break-anywhere {
  line-break: anywhere;
}

.cat-card-button {
  background-color: theme('colors.brand.lightgraytrans');
  color: theme('colors.brand.medgray');
}
/* L1 PLP Mobile */
@media (max-width: 480px) {
  .cat-level-1-hdr {
    height: 221px;
  }
  .cat-card-title {
    font-size: 24px;
    font-weight: 600;
    line-height: 29.05px;
  }
  .cat-card-img {
    margin: 0;
  }
  .cat-card-copy {
    font-weight: 500;
    line-height: 19.42px;
    letter-spacing: 0.05em;
  }
  .cat-card-sublinks {
    font-size: 20px;
    line-height: 24.2px;
    text-underline-position: from-font;
  }
}

.DefaultProgressBar__vertical {
  width: 24px;
  height: 100%;
}
.DefaultProgressBar_progressBar {
  background-color: theme('colors.brand.DEFAULT');
  position: absolute;
}
.DefaultProgressBar_progressBar__vertical {
  height: 100%;
  width: 24px;
}
.DefaultProgressBar_background__vertical {
  height: 100%;
  top: 0px;
  width: 15px;
}
.DefaultProgressBar_background__horizontal {
  height: 3px;
  top: 0px;
}
.DefaultHandle_handle {
  width: 24px;
  height: 24px;
  border-width: 1px;
  border-style: solid;
  border-color: theme('colors.grey.400');
  background-color: theme('colors.grey.50');
  border-radius: 20%;
  outline: none;
  z-index: 2;
}
.DefaultHandle_handle:after {
  content: '';
  display: block;
  position: absolute;
  background-color: theme('colors.grey.400');
}
.DefaultHandle_handle:before {
  content: '';
  display: block;
  position: absolute;
  background-color: theme('colors.grey.400');
}
.DefaultHandle_handle__horizontal {
  margin-left: -12px;
  top: -5px;
  display: block;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  border: 1px solid theme('colors.brand.DEFAULT');
  background-color: theme('colors.brand.DEFAULT');
}
.DefaultHandle_handle__horizontal:before {
  top: 7px;
  height: 1px;
  width: 1px;
  left: 10px;
}
.DefaultHandle_handle__horizontal:after {
  top: 7px;
  height: 1px;
  width: 1px;
  left: 13px;
}
.DefaultHandle_handle__vertical {
  margin-top: -12px;
  left: -10px;
}
.DefaultHandle_handle__vertical:before {
  top: 10px;
}
.DefaultHandle_handle__vertical:after {
  top: 8px;
  left: 8px;
  height: 1px;
  width: 10px;
}
.DefaultHandle_handle__disabled {
  border-color: theme('colors.grey.400');
}
.DefaultBackground {
  background-color: theme('colors.grey.50');
  height: 1px;
  width: 100%;
  border: 1px solid theme('colors.grey.400');
  position: relative;
}
.DefaultBackground_background__horizontal {
  height: 1px;
  top: 0px;
  left: -2px;
  bottom: 4px;
  width: 100%;
}
.DefaultBackground_background__vertical {
  width: 15px;
  top: 0px;
  height: 100%;
}
.rheostat {
  position: relative;
  overflow: visible;
}
@media (min-width: 1128px) {
  .autoAdjustVerticalPosition {
    top: 12px;
  }
}
.rheostat__vertical {
  height: 100%;
}
.handleContainer {
  height: 10px;
  top: -5px;
  left: -2px;
  bottom: 4px;
  width: 100%;
  position: absolute;
}
.rheostat_background {
  background-color: theme('colors.grey.50');
  border: 1px solid theme('colors.grey.400');
  position: relative;
}
.rheostat_background__horizontal {
  height: 1px;
  top: -2px;
  left: -2px;
  bottom: 4px;
  width: 100%;
}
.rheostat_background__vertical {
  width: 15px;
  top: 0px;
  height: 100%;
}
